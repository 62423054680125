@import "./Foundation/variables";
@import "./Foundation/mediaqueries";
@import "./Foundation/fontsize";


// @font-face {
//   font-family: 'notosans';
//   src: url('../fonts/NotoSansJP-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'notosans';
//   src: url('../fonts/NotoSansJP-Light.woff') format('woff');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'notosans';
//   src: url('../fonts/NotoSansJP-Medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'notosans';
//   src: url('../fonts/NotoSansJP-Bold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'notoserif';
//   src: url('../fonts/NotoSerifJP-Regular.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'notoserif';
//   src: url('../fonts/NotoSerifJP-Medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'notoserif';
//   src: url('../fonts/NotoSerifJP-Bold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'play';
//   src: url('../fonts/PlayfairDisplay-Italic.woff') format('woff');
//   font-weight: 400;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'amiri';
//   src: url('../fonts/Amiri-Regular.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

*,
body {
  font-family: $font-serif;
  letter-spacing: 0.1em;
  color: $color-default;

  @include pc {
    letter-spacing: 0.14em;
  }
}

html {
  font-size: 16px;
  scroll-padding-top: 60px;
  font-feature-settings: "palt";
}

body {
  color: $color-white;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
dt,
dd,
th,
td,
span,
a {
  line-height: 1.6;
}

img {
  width: 100%;
  vertical-align: bottom;
}

a {
  color: $color-default;
  transition: all 0.4s;

  &:hover {
    opacity: 0.7;
  }
}

.sp {
  display: block !important;

  @include pc() {
    display: none !important;
  }
}

.pc {
  display: none !important;

  @include pc() {
    display: block !important;
  }
}

.container {
  overflow-x: hidden;
}

.accordion {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
}

.accordion-title {
  color: $color-default;
  cursor: pointer;
  @include fz_vw(12);
  padding: 4px 10px;

  @include pc {
    font-size: 14px;
  }
}

.accordion-content {
  display: none;
  padding: 14px 0;
  display: flex;
  flex-wrap: wrap;

  ul {
    li {
      a {
        font-size: 13px;
      }
    }
  }
}

.accordion-item {}

/* 矢印 */
.accordion-title {
  position: relative;
  padding: 16px 0;
  border-bottom: 1px solid #C6C8C9;
}

.accordion-title::after {
  content: "";
  display: block;
  position: absolute;
  right: 25px;
  top: 50%;
  transition: transform .3s ease-in-out, top .3s ease-in-out;
  transform: translateY(-50%);
  height: 26px;
  width: 26px;
  background: url(../img/common/arrow_bottom.png) no-repeat center top/cover;
}

.accordion-title.open::after {
  transform: rotate(180deg) translateY(50%);
}

.swiper-container {
  position: relative;

  .column_wrapper {
    display: block;

    .column_content {
      width: auto;
    }
  }
}


.txt-common {
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.1em;
  font-family: $font-default;
  font-weight: 400;

  @include pc {
    font-size: 16px;
    line-height: 1.875;
  }
}

.under-page {
  margin-top: 66px;

  @include pc {
    margin-top: 72px;
    width: calc(100% - 60px);
  }
}

.sec-ttl {
  font-family: $font-play;
  font-size: 28px;
  font-style: italic;
  letter-spacing: 0.04em;
  text-align: center;
  padding-bottom: 46px;
  border-bottom: 1px solid #707070;
  margin-bottom: 60px;
  color: #4D4D4D;

  .onlydifferent {
    color: #4D4D4D;
  }

  @include pc {
    padding-bottom: 72px;
    font-size: 42px;
  }
}

.cta {
  @include pc {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
  }

  .btn {
    @include pc {
      width: 48%;
      height: 120px;
    }

    &:first-child {
      margin-bottom: 16px;
      border: none;

      @include pc {
        margin-bottom: 0;
      }
    }
  }
}

.btn {
  height: 78px;
  line-height: 78px;
  display: block;
  width: 100%;

  @include pc {
    height: 120px;
    line-height: 120px;
  }

  &.small {
    height: 60px;
    line-height: 60px;

    @include pc {
      font-size: 18px;
    }

    &.reserve {
      span {
        font-size: 14px;

        @include pc {
          font-size: 26px;
        }
      }
    }

    &.tel {
      font-size: 13px;

      @include pc {
        font-size: 18px;
      }

      span {
        font-size: 18px;
        margin: 4px 0 0;

        @include pc {
          font-size: 40px;
        }

        &::before {
          width: 10px;
          height: 14px;

          @include pc {
            width: 15px;
            height: 21px;
          }
        }
      }
    }
  }

  &.tel {
    color: $color-default;
    font-size: 14px;
    background: #fff;
    border: 1px solid $color-default;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.06em;
    font-family: $font-amiri;

    @include pc {
      font-size: 22px;
    }

    span {
      font-size: 32px;
      letter-spacing: 0.14em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 4px 0 -10px;
      line-height: 1;

      @include pc {
        font-size: 32px;
      }

      &::before {
        content: '';
        display: block;
        width: 15px;
        height: 21px;
        background: url(../img/common/icon_tel.png) no-repeat center top/cover;
        margin-right: 10px;
      }
    }
  }

  &.store {
    background: #fff;
    border: 1px solid $color-default;
    text-align: center;
  }

  &.reserve {
    color: #fff;
    background: #717679;
    font-size: 13px;
    letter-spacing: 0.2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    line-height: 1;

    span {
      font-size: 20px;
      letter-spacing: 0.14em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 6px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;

      @include pc {
        font-size: 26px;
      }

      &::before {
        width: 15px;
        height: 15px;
        margin-right: 14px;
        content: '';
        display: block;
        background: url(../img/common/icon_reserve.png) no-repeat center top/cover;

        @include pc {
          width: 26px;
          height: 26px;
          margin-right: 10px;
        }
      }
    }
  }

  &.arrow_right {
    height: 44px;
    line-height: 44px;
    border: 1px solid #AAADAF;
    text-align: center;
    font-size: 14px;
    position: relative;

    &::before {
      content: '';
      display: block;
      background: url(../img/common/arrow_paginavi.png) no-repeat center top/cover;
      width: 11px;
      height: 7px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }
  }

  &.brown {
    background: #B49C6C;
    color: #fff;
  }
}

.txt_on_picture_links {
  padding: 0 16px;

  @include pc {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0;
  }
}

.txt_on_picture {
  position: relative;

  @include pc {
    width: 49%;
    height: 160px;
  }

  &:nth-child(n+5) {
    @include pc {
      width: 32%;
    }
  }

  &:not(&:last-child) {
    margin-bottom: 15px;
  }

  &::before {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    background: url(../img/common/arrow_white.png) no-repeat center top/cover;
    position: absolute;
    bottom: 13px;
    right: 10px;
    z-index: 10;
  }

  a {
    width: 100%;
    height: 100%;
    display: block;
  }

  .name {
    color: #fff;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    text-align: center;
    z-index: 10;
    width: 100%;

    span {
      font-family: $font-play;
      font-size: 32px;
      font-style: italic;
      letter-spacing: 0.02em;
      color: #fff;
      display: block;
    }

    .onlydifferent {
      color: #fff;
    }
  }

  figure {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
      z-index: 1;
    }
  }

  img {
    height: 160px;
    display: block;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.page-inner {
  padding: 0 15px;

  @include pc {
    max-width: 1030px;
    margin: 0 auto;
  }
}

.breadcrumb {
  margin-bottom: 50px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.8;

  @include pc {
    font-size: 16px;
    margin-left: -100px;
    margin-bottom: 120px;
  }

  a {
    font-size: 14px;
    margin: 0 8px;

    @include pc {
      font-size: 16px;
    }
  }
}

.viewmore {
  font-family: $font-amiri;
  font-size: 20px;
  display: inline-block;
  border-bottom: 1px solid $color-default;
  line-height: 1.2;
}

.pagenation {
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: $font-amiri;
  align-items: center;
  line-height: 1;
  margin-bottom: 30px;

  .page-numbers {
    font-size: 18px;
    margin-right: 30px;
    position: relative;

    &.current {
      color: #B09D72;
      text-decoration: underline;
    }

    &.next {
      &::before {
        content: '';
        display: block;
        background: url(../img/common/arrow_paginavi.png) no-repeat center top/cover;
        width: 11px;
        height: 7px;
      }
    }

    &.prev {
      &::before {
        content: '';
        display: block;
        background: url(../img/common/arrow_paginavi.png) no-repeat center top/cover;
        width: 11px;
        height: 7px;
        transform: rotate(-180deg);
      }
    }
  }
}

#search_modal {
  display: none;
}

.modaal-overlay {
  background-color: transparent !important;
}

/*モーダルの横幅を変更したい場合*/
.modaal-container {
  max-width: 600px;
}

.modaal-close {
  display: none;
}

.modaal-container {
  box-shadow: 0 10px 20px rgba(112, 112, 112, 0.5) !important
}

// お客様の声共通部分

.column_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.blog_wrapper {
    margin: 0 calc(50% - 50vw);

    .blog_content {
      width: 50%;
      margin-bottom: 0;

      @include pc {
        width: 100%;
      }

      &:first-child {
        background: #F8F8F8;

        @include pc {
          background: none;
        }
      }

      &:nth-child(4) {
        background: #F8F8F8;

        @include pc {
          background: none;
        }
      }

      &:nth-child(odd) {
        @include pc {
          background: #F8F8F8;
        }
      }

      a {
        padding: 15px;
        display: block;

        @include pc {
          display: flex;
          max-width: 1000px;
          margin: 0 auto;
          padding: 60px 0;
        }

        figure {
          @include pc {
            width: 247px;
            height: 247px;
            flex-shrink: 0;
            margin-right: 28px;
          }
        }

        .txt-common {
          @include pc {
            font-size: 20px;
          }
        }

        .btn {
          @include pc {
            width: 250px;
            margin: 0;
          }
        }
      }

      .store {
        font-size: 14px;

        @include pc {
          font-size: 16px;
        }
      }

      .time {
        font-size: 14px;
      }
    }
  }
}

.column_content {
  width: 48%;
  margin-bottom: 36px;

  @include pc {
    width: 31%;
  }

  a {
    line-height: 1;
  }

  figure {
    margin-bottom: 10px;

    @include pc {
      margin-bottom: 15px;
    }
  }

  .store {
    margin-bottom: 10px;

    @include pc {
      margin-bottom: 15px;
    }
  }

  .time {
    margin-bottom: 10px;

    @include pc {
      margin-bottom: 15px;
    }
  }

  .txt-common {
    margin-bottom: 10px;

    @include pc {
      margin-bottom: 15px;
    }
  }
}

.container_with_sidebar {
  @include pc {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    flex-wrap: wrap;
  }
}

.news_wrapper {
  margin-bottom: 100px;

  @include pc {
    width: calc(100% - 238px);
  }

  .news_content {
    border-bottom: 1px solid $color-default;

    &:first-child {
      border-top: 1px solid $color-default;
    }

    a {
      padding: 20px 50px 20px 0;
      display: block;
      position: relative;

      @include pc {
        display: flex;
        width: 100%;
        padding: 30px 50px 30px 0;
      }

      &::before {
        content: '';
        display: block;
        background: url(../img/common/arrow_paginavi.png) no-repeat center top/cover;
        width: 11px;
        height: 7px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
    }

    .txt-common {
      margin-bottom: 0 !important;
    }
  }
}

.news_blog_single_wrapper {
  .cat_date {
    @include pc {
      margin-bottom: 40px;
    }

    .date {
      @include pc {
        font-size: 16px;
        margin: 0;
      }
    }
  }
}

.cat_date {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-shrink: 0;

  .cat {
    span {
      padding: 2px 4px;
      border: 1px solid $color-default;
      display: inline-block;
      margin-right: 10px;
      font-size: 13px;
    }
  }

  .date {
    font-size: 14px;

    @include pc {
      font-size: 16px;
      margin: 0 60px;
    }
  }
}

.sidebar {

  @include pc {
    order: 1;
    width: 188px;
    margin-right: 50px;
  }

  h3 {
    font-family: $font-play;
    font-size: 30px;
    font-style: italic;
    letter-spacing: 0.04em;
    margin-bottom: 20px;

    &.accordion-title {
      font-family: $font-serif;
      font-style: inherit;
      font-size: 14px;
      border-bottom: 1px solid $color-default;
      border-top: none;
      margin-bottom: 0;

      &:first-child {
        border-top: 1px solid $color-default;
      }
    }
  }

  ul {
    margin-bottom: 30px;

    &.blog-items {
      margin-bottom: 0;

      li {
        &:first-of-type {
          margin-top: 20px;
        }

        @include pc {
          margin: 0 0 10px 20px;
        }
      }
    }

    li {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $color-default;

      @include pc {
        padding-bottom: 0;
        border: none;
      }

      a {
        font-size: 14px;
      }
    }
  }

  .post-count {
    font-size: 14px;
  }
}

.collection_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 68px;

  @include pc {
    justify-content: inherit;
  }
}

.collection_item {
  width: 48%;
  text-align: center;
  margin-bottom: 60px;

  @include pc {
    width: 15.8%;
    margin-right: 50px;

    &:nth-child(5n) {
      margin-right: 0;
    }
  }

  figure {
    position: relative;

    figcaption {
      font-family: $font-amiri;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #B49C6C;
      color: #fff;
      font-size: 12px;
      line-height: 40px;
      position: absolute;
      top: -15px;
      left: -10px;
    }
  }

  .name {
    .ja {
      font-size: 12px;
      display: block;
      margin-bottom: 4px;
    }

    .en {
      font-family: $font-amiri;
      font-size: 22px;
      line-height: 1;
      display: block;
      margin-bottom: 4px;
    }
  }

  .price {
    margin-bottom: 15px;
    font-size: 12px;

    span {
      font-size: 12px;
    }
  }
}

.news_blog_single_wrapper {
  @include pc {
    width: calc(100% - 238px);
  }

  .title {
    line-height: 1.8;
    font-size: 20px;
    letter-spacing: 0.2em;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-default;

    @include pc {
      font-size: 22px;
    }
  }

  .content {
    margin-bottom: 60px;

    p {
      line-height: 2;
      font-size: 14px;
      font-family: $font-default;
      margin-bottom: 20px;

      @include pc {
        font-size: 16px;
        line-height: 1.875;
        margin-bottom: 40px;
      }
    }

    strong {
      font-family: $font-default;
    }

    b {
      font-size: 20px;
      font-family: $font-default;
      line-height: 1.5;

      @include pc {
        font-size: 22px;
        margin-bottom: 40px;
      }
    }

    a {
      color: #B49C6C;
      text-decoration: underline;
      margin-bottom: 20px;
      display: block;
      font-family: $font-default;

      @include pc {
        font-size: 18px;
        margin-bottom: 40px;
      }
    }

    h2 {
      background: #F5F4F0;
      padding: 10px 15px;
      margin-bottom: 10px;

      @include pc {
        font-size: 22px;
        margin-bottom: 15px;
      }
    }

    h3 {
      background: #F8F8F8;
      padding: 10px 15px;
      margin-bottom: 10px;

      @include pc {
        font-size: 18px;
        margin-bottom: 15px;
      }
    }

    h4 {
      padding: 10px 15px;
      border: 1px solid $color-default;
      margin-bottom: 10px;
      margin-bottom: 15px;

      @include pc {
        font-size: 18px;
      }
    }

    h5 {
      padding: 10px 0;
      border-bottom: 1px solid $color-default;
      margin-bottom: 10px;
      margin-bottom: 15px;
    }

    blockquote {
      padding: 50px 15px;
      background: #F8F8F8;
      line-height: 1.5;
      position: relative;
      font-family: $font-default;

      &::before,
      &::after {
        content: '';
        display: block;
        width: 16px;
        height: 14px;
        background: url(../img/common/inyo.png) no-repeat center top/cover;
        position: absolute;
        left: 15px;
        top: 20px;
      }

      &::after {
        left: inherit;
        right: 15px;
        top: inherit;
        bottom: 20px;
        transform: rotate(180deg);
      }

      p {
        margin-bottom: 0;
        font-family: $font-serif;

        @include pc {
          font-size: 18px;
        }
      }
    }


    ul,
    ol {
      margin-bottom: 20px;
      list-style-type: decimal;
      list-style-position: inside;
      font-family: $font-default;
    }
  }
}

.swiper-container {
  position: relative;

  .swiper-button-next,
  .swiper-button-prev {
    width: 50px;
    height: 50px;
    background: url(../img/common/arrow_dark.png) no-repeat center top/cover;
    right: 0px;
    top: -60px;

    @include pc {
      width: 64px;
      height: 64px;
    }

    &::after {
      content: '';
    }
  }

  .swiper-button-prev {
    right: 60px;
    left: inherit;
    position: absolute;
    transform: rotate(180deg);
  }
}

.prev-next {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  border-top: 1px solid $color-default;
  border-bottom: 1px solid $color-default;
  margin-bottom: 30px;
  width: 100%;

  @include pc {
    order: 3;
    margin-bottom: 0;
  }

  a[rel="next"] {
    display: flex;
    align-items: center;

    &::after {
      content: '';
      display: block;
      width: 14px;
      height: 10px;
      background: url(../img/common/arrow_paginavi.png) no-repeat center top/cover;
      margin-left: 10px;
    }
  }

  a[rel="prev"] {
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      width: 14px;
      height: 10px;
      background: url(../img/common/arrow_paginavi.png) no-repeat center top/cover;
      margin-right: 10px;
      transform: rotate(180deg);
    }
  }

  @include pc {
    padding: 40px 0;
  }
}

.stoker_btn {
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  right: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background: #fff;
  z-index: 20;

  @media screen and (min-width: 575px) {
    width: 60px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background: #fff;
    z-index: 20;
  }

  #reserve_popup_header {
    position: absolute;
    display: block;
    bottom: 0;
    width: 100%;
    margin-top: auto;
  }

  .drawer {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #aa926c;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: all 0.4s;
    bottom: -178px;

    @media screen and (min-width: 575px) {
      width: 220px;
      position: absolute;
      right: 0;
      bottom: 0;
      background: #aa926c;
      display: flex;
      flex-direction: row;
      align-items: center;
      right: -220px;
      transition: all 0.4s;
    }

    &.slide {
      bottom: 0;

      @media screen and (min-width: 575px) {
        right: 0;
      }
    }

    img {
      width: 100%;
      flex-shrink: 0;
      cursor: pointer;
      display: block;
      margin-bottom: 20px;

      @media screen and (min-width: 575px) {
        height: 190px;
        margin-bottom: 0;
      }
    }

    .drawer_box {
      margin: 0 0 20px 0;

      @media screen and (min-width: 575px) {
        margin: 0 0 0 20px;
      }

      a {
        font-size: 12px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: #4d4d4d;

        span {
          margin-bottom: 4px;
          padding-bottom: 4px;
          border-bottom: 1px solid #4d4d4d;
          color: #000;
          display: flex;
        }

        &.web {
          margin-bottom: 20px;
          color: #000;

          @media screen and (min-width: 575px) {
            margin-bottom: 40px;
          }

          &::before {
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            background: url(../img/common/icon_web_black.png) no-repeat center top/cover;
            margin-right: 10px;
          }
        }

        &.tel {
          display: flex;
          flex-direction: column;

          span {
            &::before {
              content: '';
              display: block;
              width: 16px;
              height: 16px;
              background: url(../img/common/icon_tel_black.png) no-repeat center top/cover;
              margin-right: 10px;
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
}


.reserve_popup_header {
  display: none;
}

.modal {
  display: none;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: none;

  .modal-box {
    box-shadow: 0 10px 20px rgba(112, 112, 112, 0.5) !important;
    background-color: #fff;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    max-width: 1000px;
    width: 100%;
    -webkit-animation-name: modalopen;
    animation-name: modalopen;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    padding: 20px 10px;
    z-index: 100;

    @include pc {
      padding: 48px 90px;
    }

    h3 {
      text-align: center;
      margin-bottom: 10px;

      @include pc {
        font-size: 19px;
        margin-bottom: 20px;
      }
    }

    .btn {
      margin-bottom: 10px !important;

      @include pc {
        margin-bottom: 20px !important;
      }

      &.gray {
        background: #E3E3E3;

        &:hover {
          background: #B49C6C;
          color: #fff;

          &::before {
            background: url(../img/common/arrow_paginavi_white.png) no-repeat center top/cover;
          }
        }
      }

      &.arrow_right {
        line-height: 1.2;
        height: auto;
        padding: 14px 0;
        width: 100% !important;
        height: auto !important;
        border: 1px solid #AAADAF !important;

        @include pc {
          padding: 26px 0;
          font-size: 19px;
        }

        span {
          display: block;
        }
      }
    }

    .modalClose {
      text-align: center;
      background: #fff;
      margin: 0 auto;
      width: 100%;
      font-size: 16px;
      width: 200px;
      margin: 0 auto;
      display: block;
      border: 1px solid #AAADAF;
    }
  }
}

.category_unique {
  margin-bottom: 90px;

  @include pc {
    margin: 0 calc(50% - 50vw) 120px;
    padding-top: 120px;
    position: relative;
  }

  .category_unique_inner {
    @include pc {
      display: flex;
      align-items: flex-end;
      max-width: 1000px;
      margin: 0 auto;
    }
  }


  figure {
    width: calc(100% - 30px);
    margin: 0 0 38px auto;
    padding-top: 38px;
    position: relative;

    @include pc {
      width: 433px;
      margin: 0 42px 0 0;
      flex-shrink: 0;
      padding: 0;
      position: static;
    }

    .name {
      width: 100%;
      position: absolute;
      top: -38px;
      left: -30px;
      z-index: -1;

      @include pc {
        transform: translateX(-50%);
        left: 50%;
        position: static;
        position: absolute;
        top: -50px;
        text-align: center;
      }

      @include pc {
        img {
          height: 254px;
          width: auto;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }
    }
  }

  .annotation {
    font-size: 12px;
    font-family: $font-default;
    margin-top: 20px;
  }

  .tax_ttl {
    font-size: 18px;
    letter-spacing: 0.2em;
    margin-bottom: 15px;
    line-height: 1.5;

    @include pc {
      font-size: 25px;
    }
  }
}

.wp-block-image {
  margin-bottom: 20px;

  img {
    width: auto;
  }
}

.onlydifferent {
  font-style: italic;
  font-family: 'EB Garamond', serif;
  font-weight: normal;
}