$color-default: #717679;
$color-white: #fff;

$font-default: "Noto sans JP",
sans-serif;
$font-serif: "Noto serif JP",
serif;
$font-play: "Playfair Display",
serif;
$font-amiri:"Amiri",
serif;