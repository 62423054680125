// メディアクエリ

// フォントサイズ
// メディアクエリに変換してくれる

// @include pc-layout() {
//     color: #fff;
// }
// @include sp-layout() {
//     color: #000;
// }

// こんな感じで記述する。
// 案件によってブレイクポイントは変更してね。

// ブレイクポイントが2つの場合
// @mixin pc-layout($width: 768px) {
//     @media only screen and (min-width: $width) {
//         @content;
//     }
// }
// @mixin sp-layout($width: 767px) {
//     @media only screen and (max-width: $width) {
//         @content;
//     }
// }

// ブレイクポイントが3つの場合
@mixin pc($width: 821px) {
  @media only screen and (min-width: $width) {
    @content;
  }
}

@mixin tab($width: 576px) {
  @media only screen and (min-width: $width) {
    @content;
  }
}

@mixin sp($width: 575px) {
  @media only screen and (max-width: $width) {
    @content;
  }
}