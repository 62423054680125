@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  word-wrap: break-word;
}

input,
textarea {
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: 100%;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: bottom;
}

*,
body {
  font-family: "Noto serif JP", serif;
  letter-spacing: 0.1em;
  color: #717679;
}
@media only screen and (min-width: 821px) {
  *,
  body {
    letter-spacing: 0.14em;
  }
}

html {
  font-size: 16px;
  scroll-padding-top: 60px;
  font-feature-settings: "palt";
}

body {
  color: #fff;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
dt,
dd,
th,
td,
span,
a {
  line-height: 1.6;
}

img {
  width: 100%;
  vertical-align: bottom;
}

a {
  color: #717679;
  transition: all 0.4s;
}
a:hover {
  opacity: 0.7;
}

.sp {
  display: block !important;
}
@media only screen and (min-width: 821px) {
  .sp {
    display: none !important;
  }
}

.pc {
  display: none !important;
}
@media only screen and (min-width: 821px) {
  .pc {
    display: block !important;
  }
}

.container {
  overflow-x: hidden;
}

.accordion {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
}

.accordion-title {
  color: #717679;
  cursor: pointer;
  font-size: 12px;
  font-size: 3.2vw;
  padding: 4px 10px;
}
@media only screen and (min-width: 821px) {
  .accordion-title {
    font-size: 14px;
  }
}

.accordion-content {
  display: none;
  padding: 14px 0;
  display: flex;
  flex-wrap: wrap;
}
.accordion-content ul li a {
  font-size: 13px;
}

/* 矢印 */
.accordion-title {
  position: relative;
  padding: 16px 0;
  border-bottom: 1px solid #C6C8C9;
}

.accordion-title::after {
  content: "";
  display: block;
  position: absolute;
  right: 25px;
  top: 50%;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
  transform: translateY(-50%);
  height: 26px;
  width: 26px;
  background: url(../img/common/arrow_bottom.png) no-repeat center top/cover;
}

.accordion-title.open::after {
  transform: rotate(180deg) translateY(50%);
}

.swiper-container {
  position: relative;
}
.swiper-container .column_wrapper {
  display: block;
}
.swiper-container .column_wrapper .column_content {
  width: auto;
}

.txt-common {
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.1em;
  font-family: "Noto sans JP", sans-serif;
  font-weight: 400;
}
@media only screen and (min-width: 821px) {
  .txt-common {
    font-size: 16px;
    line-height: 1.875;
  }
}

.under-page {
  margin-top: 66px;
}
@media only screen and (min-width: 821px) {
  .under-page {
    margin-top: 72px;
    width: calc(100% - 60px);
  }
}

.sec-ttl {
  font-family: "Playfair Display", serif;
  font-size: 28px;
  font-style: italic;
  letter-spacing: 0.04em;
  text-align: center;
  padding-bottom: 46px;
  border-bottom: 1px solid #707070;
  margin-bottom: 60px;
  color: #4D4D4D;
}
.sec-ttl .onlydifferent {
  color: #4D4D4D;
}
@media only screen and (min-width: 821px) {
  .sec-ttl {
    padding-bottom: 72px;
    font-size: 42px;
  }
}

@media only screen and (min-width: 821px) {
  .cta {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
  }
}
@media only screen and (min-width: 821px) {
  .cta .btn {
    width: 48%;
    height: 120px;
  }
}
.cta .btn:first-child {
  margin-bottom: 16px;
  border: none;
}
@media only screen and (min-width: 821px) {
  .cta .btn:first-child {
    margin-bottom: 0;
  }
}

.btn {
  height: 78px;
  line-height: 78px;
  display: block;
  width: 100%;
}
@media only screen and (min-width: 821px) {
  .btn {
    height: 120px;
    line-height: 120px;
  }
}
.btn.small {
  height: 60px;
  line-height: 60px;
}
@media only screen and (min-width: 821px) {
  .btn.small {
    font-size: 18px;
  }
}
.btn.small.reserve span {
  font-size: 14px;
}
@media only screen and (min-width: 821px) {
  .btn.small.reserve span {
    font-size: 26px;
  }
}
.btn.small.tel {
  font-size: 13px;
}
@media only screen and (min-width: 821px) {
  .btn.small.tel {
    font-size: 18px;
  }
}
.btn.small.tel span {
  font-size: 18px;
  margin: 4px 0 0;
}
@media only screen and (min-width: 821px) {
  .btn.small.tel span {
    font-size: 40px;
  }
}
.btn.small.tel span::before {
  width: 10px;
  height: 14px;
}
@media only screen and (min-width: 821px) {
  .btn.small.tel span::before {
    width: 15px;
    height: 21px;
  }
}
.btn.tel {
  color: #717679;
  font-size: 14px;
  background: #fff;
  border: 1px solid #717679;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.06em;
  font-family: "Amiri", serif;
}
@media only screen and (min-width: 821px) {
  .btn.tel {
    font-size: 22px;
  }
}
.btn.tel span {
  font-size: 32px;
  letter-spacing: 0.14em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px 0 -10px;
  line-height: 1;
}
@media only screen and (min-width: 821px) {
  .btn.tel span {
    font-size: 32px;
  }
}
.btn.tel span::before {
  content: "";
  display: block;
  width: 15px;
  height: 21px;
  background: url(../img/common/icon_tel.png) no-repeat center top/cover;
  margin-right: 10px;
}
.btn.store {
  background: #fff;
  border: 1px solid #717679;
  text-align: center;
}
.btn.reserve {
  color: #fff;
  background: #717679;
  font-size: 13px;
  letter-spacing: 0.2em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  line-height: 1;
}
.btn.reserve span {
  font-size: 20px;
  letter-spacing: 0.14em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
@media only screen and (min-width: 821px) {
  .btn.reserve span {
    font-size: 26px;
  }
}
.btn.reserve span::before {
  width: 15px;
  height: 15px;
  margin-right: 14px;
  content: "";
  display: block;
  background: url(../img/common/icon_reserve.png) no-repeat center top/cover;
}
@media only screen and (min-width: 821px) {
  .btn.reserve span::before {
    width: 26px;
    height: 26px;
    margin-right: 10px;
  }
}
.btn.arrow_right {
  height: 44px;
  line-height: 44px;
  border: 1px solid #AAADAF;
  text-align: center;
  font-size: 14px;
  position: relative;
}
.btn.arrow_right::before {
  content: "";
  display: block;
  background: url(../img/common/arrow_paginavi.png) no-repeat center top/cover;
  width: 11px;
  height: 7px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.btn.brown {
  background: #B49C6C;
  color: #fff;
}

.txt_on_picture_links {
  padding: 0 16px;
}
@media only screen and (min-width: 821px) {
  .txt_on_picture_links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0;
  }
}

.txt_on_picture {
  position: relative;
}
@media only screen and (min-width: 821px) {
  .txt_on_picture {
    width: 49%;
    height: 160px;
  }
}
@media only screen and (min-width: 821px) {
  .txt_on_picture:nth-child(n+5) {
    width: 32%;
  }
}
.txt_on_picture:not(.txt_on_picture:last-child) {
  margin-bottom: 15px;
}
.txt_on_picture::before {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background: url(../img/common/arrow_white.png) no-repeat center top/cover;
  position: absolute;
  bottom: 13px;
  right: 10px;
  z-index: 10;
}
.txt_on_picture a {
  width: 100%;
  height: 100%;
  display: block;
}
.txt_on_picture .name {
  color: #fff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  text-align: center;
  z-index: 10;
  width: 100%;
}
.txt_on_picture .name span {
  font-family: "Playfair Display", serif;
  font-size: 32px;
  font-style: italic;
  letter-spacing: 0.02em;
  color: #fff;
  display: block;
}
.txt_on_picture .name .onlydifferent {
  color: #fff;
}
.txt_on_picture figure::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.txt_on_picture img {
  height: 160px;
  display: block;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.page-inner {
  padding: 0 15px;
}
@media only screen and (min-width: 821px) {
  .page-inner {
    max-width: 1030px;
    margin: 0 auto;
  }
}

.breadcrumb {
  margin-bottom: 50px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.8;
}
@media only screen and (min-width: 821px) {
  .breadcrumb {
    font-size: 16px;
    margin-left: -100px;
    margin-bottom: 120px;
  }
}
.breadcrumb a {
  font-size: 14px;
  margin: 0 8px;
}
@media only screen and (min-width: 821px) {
  .breadcrumb a {
    font-size: 16px;
  }
}

.viewmore {
  font-family: "Amiri", serif;
  font-size: 20px;
  display: inline-block;
  border-bottom: 1px solid #717679;
  line-height: 1.2;
}

.pagenation {
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: "Amiri", serif;
  align-items: center;
  line-height: 1;
  margin-bottom: 30px;
}
.pagenation .page-numbers {
  font-size: 18px;
  margin-right: 30px;
  position: relative;
}
.pagenation .page-numbers.current {
  color: #B09D72;
  text-decoration: underline;
}
.pagenation .page-numbers.next::before {
  content: "";
  display: block;
  background: url(../img/common/arrow_paginavi.png) no-repeat center top/cover;
  width: 11px;
  height: 7px;
}
.pagenation .page-numbers.prev::before {
  content: "";
  display: block;
  background: url(../img/common/arrow_paginavi.png) no-repeat center top/cover;
  width: 11px;
  height: 7px;
  transform: rotate(-180deg);
}

#search_modal {
  display: none;
}

.modaal-overlay {
  background-color: transparent !important;
}

/*モーダルの横幅を変更したい場合*/
.modaal-container {
  max-width: 600px;
}

.modaal-close {
  display: none;
}

.modaal-container {
  box-shadow: 0 10px 20px rgba(112, 112, 112, 0.5) !important;
}

.column_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.column_wrapper.blog_wrapper {
  margin: 0 calc(50% - 50vw);
}
.column_wrapper.blog_wrapper .blog_content {
  width: 50%;
  margin-bottom: 0;
}
@media only screen and (min-width: 821px) {
  .column_wrapper.blog_wrapper .blog_content {
    width: 100%;
  }
}
.column_wrapper.blog_wrapper .blog_content:first-child {
  background: #F8F8F8;
}
@media only screen and (min-width: 821px) {
  .column_wrapper.blog_wrapper .blog_content:first-child {
    background: none;
  }
}
.column_wrapper.blog_wrapper .blog_content:nth-child(4) {
  background: #F8F8F8;
}
@media only screen and (min-width: 821px) {
  .column_wrapper.blog_wrapper .blog_content:nth-child(4) {
    background: none;
  }
}
@media only screen and (min-width: 821px) {
  .column_wrapper.blog_wrapper .blog_content:nth-child(odd) {
    background: #F8F8F8;
  }
}
.column_wrapper.blog_wrapper .blog_content a {
  padding: 15px;
  display: block;
}
@media only screen and (min-width: 821px) {
  .column_wrapper.blog_wrapper .blog_content a {
    display: flex;
    max-width: 1000px;
    margin: 0 auto;
    padding: 60px 0;
  }
}
@media only screen and (min-width: 821px) {
  .column_wrapper.blog_wrapper .blog_content a figure {
    width: 247px;
    height: 247px;
    flex-shrink: 0;
    margin-right: 28px;
  }
}
@media only screen and (min-width: 821px) {
  .column_wrapper.blog_wrapper .blog_content a .txt-common {
    font-size: 20px;
  }
}
@media only screen and (min-width: 821px) {
  .column_wrapper.blog_wrapper .blog_content a .btn {
    width: 250px;
    margin: 0;
  }
}
.column_wrapper.blog_wrapper .blog_content .store {
  font-size: 14px;
}
@media only screen and (min-width: 821px) {
  .column_wrapper.blog_wrapper .blog_content .store {
    font-size: 16px;
  }
}
.column_wrapper.blog_wrapper .blog_content .time {
  font-size: 14px;
}

.column_content {
  width: 48%;
  margin-bottom: 36px;
}
@media only screen and (min-width: 821px) {
  .column_content {
    width: 31%;
  }
}
.column_content a {
  line-height: 1;
}
.column_content figure {
  margin-bottom: 10px;
}
@media only screen and (min-width: 821px) {
  .column_content figure {
    margin-bottom: 15px;
  }
}
.column_content .store {
  margin-bottom: 10px;
}
@media only screen and (min-width: 821px) {
  .column_content .store {
    margin-bottom: 15px;
  }
}
.column_content .time {
  margin-bottom: 10px;
}
@media only screen and (min-width: 821px) {
  .column_content .time {
    margin-bottom: 15px;
  }
}
.column_content .txt-common {
  margin-bottom: 10px;
}
@media only screen and (min-width: 821px) {
  .column_content .txt-common {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 821px) {
  .container_with_sidebar {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    flex-wrap: wrap;
  }
}

.news_wrapper {
  margin-bottom: 100px;
}
@media only screen and (min-width: 821px) {
  .news_wrapper {
    width: calc(100% - 238px);
  }
}
.news_wrapper .news_content {
  border-bottom: 1px solid #717679;
}
.news_wrapper .news_content:first-child {
  border-top: 1px solid #717679;
}
.news_wrapper .news_content a {
  padding: 20px 50px 20px 0;
  display: block;
  position: relative;
}
@media only screen and (min-width: 821px) {
  .news_wrapper .news_content a {
    display: flex;
    width: 100%;
    padding: 30px 50px 30px 0;
  }
}
.news_wrapper .news_content a::before {
  content: "";
  display: block;
  background: url(../img/common/arrow_paginavi.png) no-repeat center top/cover;
  width: 11px;
  height: 7px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.news_wrapper .news_content .txt-common {
  margin-bottom: 0 !important;
}

@media only screen and (min-width: 821px) {
  .news_blog_single_wrapper .cat_date {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 821px) {
  .news_blog_single_wrapper .cat_date .date {
    font-size: 16px;
    margin: 0;
  }
}

.cat_date {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-shrink: 0;
}
.cat_date .cat span {
  padding: 2px 4px;
  border: 1px solid #717679;
  display: inline-block;
  margin-right: 10px;
  font-size: 13px;
}
.cat_date .date {
  font-size: 14px;
}
@media only screen and (min-width: 821px) {
  .cat_date .date {
    font-size: 16px;
    margin: 0 60px;
  }
}

@media only screen and (min-width: 821px) {
  .sidebar {
    order: 1;
    width: 188px;
    margin-right: 50px;
  }
}
.sidebar h3 {
  font-family: "Playfair Display", serif;
  font-size: 30px;
  font-style: italic;
  letter-spacing: 0.04em;
  margin-bottom: 20px;
}
.sidebar h3.accordion-title {
  font-family: "Noto serif JP", serif;
  font-style: inherit;
  font-size: 14px;
  border-bottom: 1px solid #717679;
  border-top: none;
  margin-bottom: 0;
}
.sidebar h3.accordion-title:first-child {
  border-top: 1px solid #717679;
}
.sidebar ul {
  margin-bottom: 30px;
}
.sidebar ul.blog-items {
  margin-bottom: 0;
}
.sidebar ul.blog-items li:first-of-type {
  margin-top: 20px;
}
@media only screen and (min-width: 821px) {
  .sidebar ul.blog-items li {
    margin: 0 0 10px 20px;
  }
}
.sidebar ul li {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #717679;
}
@media only screen and (min-width: 821px) {
  .sidebar ul li {
    padding-bottom: 0;
    border: none;
  }
}
.sidebar ul li a {
  font-size: 14px;
}
.sidebar .post-count {
  font-size: 14px;
}

.collection_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 68px;
}
@media only screen and (min-width: 821px) {
  .collection_wrapper {
    justify-content: inherit;
  }
}

.collection_item {
  width: 48%;
  text-align: center;
  margin-bottom: 60px;
}
@media only screen and (min-width: 821px) {
  .collection_item {
    width: 15.8%;
    margin-right: 50px;
  }
  .collection_item:nth-child(5n) {
    margin-right: 0;
  }
}
.collection_item figure {
  position: relative;
}
.collection_item figure figcaption {
  font-family: "Amiri", serif;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #B49C6C;
  color: #fff;
  font-size: 12px;
  line-height: 40px;
  position: absolute;
  top: -15px;
  left: -10px;
}
.collection_item .name .ja {
  font-size: 12px;
  display: block;
  margin-bottom: 4px;
}
.collection_item .name .en {
  font-family: "Amiri", serif;
  font-size: 22px;
  line-height: 1;
  display: block;
  margin-bottom: 4px;
}
.collection_item .price {
  margin-bottom: 15px;
  font-size: 12px;
}
.collection_item .price span {
  font-size: 12px;
}

@media only screen and (min-width: 821px) {
  .news_blog_single_wrapper {
    width: calc(100% - 238px);
  }
}
.news_blog_single_wrapper .title {
  line-height: 1.8;
  font-size: 20px;
  letter-spacing: 0.2em;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #717679;
}
@media only screen and (min-width: 821px) {
  .news_blog_single_wrapper .title {
    font-size: 22px;
  }
}
.news_blog_single_wrapper .content {
  margin-bottom: 60px;
}
.news_blog_single_wrapper .content p {
  line-height: 2;
  font-size: 14px;
  font-family: "Noto sans JP", sans-serif;
  margin-bottom: 20px;
}
@media only screen and (min-width: 821px) {
  .news_blog_single_wrapper .content p {
    font-size: 16px;
    line-height: 1.875;
    margin-bottom: 40px;
  }
}
.news_blog_single_wrapper .content strong {
  font-family: "Noto sans JP", sans-serif;
}
.news_blog_single_wrapper .content b {
  font-size: 20px;
  font-family: "Noto sans JP", sans-serif;
  line-height: 1.5;
}
@media only screen and (min-width: 821px) {
  .news_blog_single_wrapper .content b {
    font-size: 22px;
    margin-bottom: 40px;
  }
}
.news_blog_single_wrapper .content a {
  color: #B49C6C;
  text-decoration: underline;
  margin-bottom: 20px;
  display: block;
  font-family: "Noto sans JP", sans-serif;
}
@media only screen and (min-width: 821px) {
  .news_blog_single_wrapper .content a {
    font-size: 18px;
    margin-bottom: 40px;
  }
}
.news_blog_single_wrapper .content h2 {
  background: #F5F4F0;
  padding: 10px 15px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 821px) {
  .news_blog_single_wrapper .content h2 {
    font-size: 22px;
    margin-bottom: 15px;
  }
}
.news_blog_single_wrapper .content h3 {
  background: #F8F8F8;
  padding: 10px 15px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 821px) {
  .news_blog_single_wrapper .content h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
.news_blog_single_wrapper .content h4 {
  padding: 10px 15px;
  border: 1px solid #717679;
  margin-bottom: 10px;
  margin-bottom: 15px;
}
@media only screen and (min-width: 821px) {
  .news_blog_single_wrapper .content h4 {
    font-size: 18px;
  }
}
.news_blog_single_wrapper .content h5 {
  padding: 10px 0;
  border-bottom: 1px solid #717679;
  margin-bottom: 10px;
  margin-bottom: 15px;
}
.news_blog_single_wrapper .content blockquote {
  padding: 50px 15px;
  background: #F8F8F8;
  line-height: 1.5;
  position: relative;
  font-family: "Noto sans JP", sans-serif;
}
.news_blog_single_wrapper .content blockquote::before, .news_blog_single_wrapper .content blockquote::after {
  content: "";
  display: block;
  width: 16px;
  height: 14px;
  background: url(../img/common/inyo.png) no-repeat center top/cover;
  position: absolute;
  left: 15px;
  top: 20px;
}
.news_blog_single_wrapper .content blockquote::after {
  left: inherit;
  right: 15px;
  top: inherit;
  bottom: 20px;
  transform: rotate(180deg);
}
.news_blog_single_wrapper .content blockquote p {
  margin-bottom: 0;
  font-family: "Noto serif JP", serif;
}
@media only screen and (min-width: 821px) {
  .news_blog_single_wrapper .content blockquote p {
    font-size: 18px;
  }
}
.news_blog_single_wrapper .content ul,
.news_blog_single_wrapper .content ol {
  margin-bottom: 20px;
  list-style-type: decimal;
  list-style-position: inside;
  font-family: "Noto sans JP", sans-serif;
}

.swiper-container {
  position: relative;
}
.swiper-container .swiper-button-next,
.swiper-container .swiper-button-prev {
  width: 50px;
  height: 50px;
  background: url(../img/common/arrow_dark.png) no-repeat center top/cover;
  right: 0px;
  top: -60px;
}
@media only screen and (min-width: 821px) {
  .swiper-container .swiper-button-next,
  .swiper-container .swiper-button-prev {
    width: 64px;
    height: 64px;
  }
}
.swiper-container .swiper-button-next::after,
.swiper-container .swiper-button-prev::after {
  content: "";
}
.swiper-container .swiper-button-prev {
  right: 60px;
  left: inherit;
  position: absolute;
  transform: rotate(180deg);
}

.prev-next {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  border-top: 1px solid #717679;
  border-bottom: 1px solid #717679;
  margin-bottom: 30px;
  width: 100%;
}
@media only screen and (min-width: 821px) {
  .prev-next {
    order: 3;
    margin-bottom: 0;
  }
}
.prev-next a[rel=next] {
  display: flex;
  align-items: center;
}
.prev-next a[rel=next]::after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  background: url(../img/common/arrow_paginavi.png) no-repeat center top/cover;
  margin-left: 10px;
}
.prev-next a[rel=prev] {
  display: flex;
  align-items: center;
}
.prev-next a[rel=prev]::before {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  background: url(../img/common/arrow_paginavi.png) no-repeat center top/cover;
  margin-right: 10px;
  transform: rotate(180deg);
}
@media only screen and (min-width: 821px) {
  .prev-next {
    padding: 40px 0;
  }
}

.stoker_btn {
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  right: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background: #fff;
  z-index: 20;
}
@media screen and (min-width: 575px) {
  .stoker_btn {
    width: 60px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background: #fff;
    z-index: 20;
  }
}
.stoker_btn #reserve_popup_header {
  position: absolute;
  display: block;
  bottom: 0;
  width: 100%;
  margin-top: auto;
}
.stoker_btn .drawer {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #aa926c;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: all 0.4s;
  bottom: -178px;
}
@media screen and (min-width: 575px) {
  .stoker_btn .drawer {
    width: 220px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #aa926c;
    display: flex;
    flex-direction: row;
    align-items: center;
    right: -220px;
    transition: all 0.4s;
  }
}
.stoker_btn .drawer.slide {
  bottom: 0;
}
@media screen and (min-width: 575px) {
  .stoker_btn .drawer.slide {
    right: 0;
  }
}
.stoker_btn .drawer img {
  width: 100%;
  flex-shrink: 0;
  cursor: pointer;
  display: block;
  margin-bottom: 20px;
}
@media screen and (min-width: 575px) {
  .stoker_btn .drawer img {
    height: 190px;
    margin-bottom: 0;
  }
}
.stoker_btn .drawer .drawer_box {
  margin: 0 0 20px 0;
}
@media screen and (min-width: 575px) {
  .stoker_btn .drawer .drawer_box {
    margin: 0 0 0 20px;
  }
}
.stoker_btn .drawer .drawer_box a {
  font-size: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #4d4d4d;
}
.stoker_btn .drawer .drawer_box a span {
  margin-bottom: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #4d4d4d;
  color: #000;
  display: flex;
}
.stoker_btn .drawer .drawer_box a.web {
  margin-bottom: 20px;
  color: #000;
}
@media screen and (min-width: 575px) {
  .stoker_btn .drawer .drawer_box a.web {
    margin-bottom: 40px;
  }
}
.stoker_btn .drawer .drawer_box a.web::before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: url(../img/common/icon_web_black.png) no-repeat center top/cover;
  margin-right: 10px;
}
.stoker_btn .drawer .drawer_box a.tel {
  display: flex;
  flex-direction: column;
}
.stoker_btn .drawer .drawer_box a.tel span::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: url(../img/common/icon_tel_black.png) no-repeat center top/cover;
  margin-right: 10px;
  flex-shrink: 0;
}

.reserve_popup_header {
  display: none;
}

.modal {
  display: none;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: none;
}
.modal .modal-box {
  box-shadow: 0 10px 20px rgba(112, 112, 112, 0.5) !important;
  background-color: #fff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  max-width: 1000px;
  width: 100%;
  -webkit-animation-name: modalopen;
  animation-name: modalopen;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  padding: 20px 10px;
  z-index: 100;
}
@media only screen and (min-width: 821px) {
  .modal .modal-box {
    padding: 48px 90px;
  }
}
.modal .modal-box h3 {
  text-align: center;
  margin-bottom: 10px;
}
@media only screen and (min-width: 821px) {
  .modal .modal-box h3 {
    font-size: 19px;
    margin-bottom: 20px;
  }
}
.modal .modal-box .btn {
  margin-bottom: 10px !important;
}
@media only screen and (min-width: 821px) {
  .modal .modal-box .btn {
    margin-bottom: 20px !important;
  }
}
.modal .modal-box .btn.gray {
  background: #E3E3E3;
}
.modal .modal-box .btn.gray:hover {
  background: #B49C6C;
  color: #fff;
}
.modal .modal-box .btn.gray:hover::before {
  background: url(../img/common/arrow_paginavi_white.png) no-repeat center top/cover;
}
.modal .modal-box .btn.arrow_right {
  line-height: 1.2;
  height: auto;
  padding: 14px 0;
  width: 100% !important;
  height: auto !important;
  border: 1px solid #AAADAF !important;
}
@media only screen and (min-width: 821px) {
  .modal .modal-box .btn.arrow_right {
    padding: 26px 0;
    font-size: 19px;
  }
}
.modal .modal-box .btn.arrow_right span {
  display: block;
}
.modal .modal-box .modalClose {
  text-align: center;
  background: #fff;
  margin: 0 auto;
  width: 100%;
  font-size: 16px;
  width: 200px;
  margin: 0 auto;
  display: block;
  border: 1px solid #AAADAF;
}

.category_unique {
  margin-bottom: 90px;
}
@media only screen and (min-width: 821px) {
  .category_unique {
    margin: 0 calc(50% - 50vw) 120px;
    padding-top: 120px;
    position: relative;
  }
}
@media only screen and (min-width: 821px) {
  .category_unique .category_unique_inner {
    display: flex;
    align-items: flex-end;
    max-width: 1000px;
    margin: 0 auto;
  }
}
.category_unique figure {
  width: calc(100% - 30px);
  margin: 0 0 38px auto;
  padding-top: 38px;
  position: relative;
}
@media only screen and (min-width: 821px) {
  .category_unique figure {
    width: 433px;
    margin: 0 42px 0 0;
    flex-shrink: 0;
    padding: 0;
    position: static;
  }
}
.category_unique figure .name {
  width: 100%;
  position: absolute;
  top: -38px;
  left: -30px;
  z-index: -1;
}
@media only screen and (min-width: 821px) {
  .category_unique figure .name {
    transform: translateX(-50%);
    left: 50%;
    position: static;
    position: absolute;
    top: -50px;
    text-align: center;
  }
}
@media only screen and (min-width: 821px) {
  .category_unique figure .name img {
    height: 254px;
    width: auto;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.category_unique .annotation {
  font-size: 12px;
  font-family: "Noto sans JP", sans-serif;
  margin-top: 20px;
}
.category_unique .tax_ttl {
  font-size: 18px;
  letter-spacing: 0.2em;
  margin-bottom: 15px;
  line-height: 1.5;
}
@media only screen and (min-width: 821px) {
  .category_unique .tax_ttl {
    font-size: 25px;
  }
}

.wp-block-image {
  margin-bottom: 20px;
}
.wp-block-image img {
  width: auto;
}

.onlydifferent {
  font-style: italic;
  font-family: "EB Garamond", serif;
  font-weight: normal;
}

body.is-drawerActive {
  overflow-y: hidden;
}
body.is-drawerActive .humburger-wrap .button-wrap::before {
  content: "close";
}

body.is-drawerActive .l-header .nav-wrap {
  opacity: 1;
  visibility: visible;
}

.l-header {
  width: 100%;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
@media only screen and (min-width: 821px) {
  .l-header {
    background: none;
  }
}
.l-header .inner {
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (min-width: 821px) {
  .l-header .inner {
    padding: 10px 10px 20px 36px;
  }
}
.l-header .logo-wrap {
  position: relative;
  z-index: 9999;
}
.l-header .logo-wrap img {
  width: auto;
  height: 32px;
}
@media only screen and (min-width: 821px) {
  .l-header .logo-wrap img {
    height: 45px;
  }
}
.l-header .humburger-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
@media only screen and (min-width: 821px) {
  .l-header .humburger-wrap {
    align-items: center;
    position: relative;
    z-index: 9999;
  }
}
.l-header .humburger-wrap .incart-btn {
  text-align: center;
  margin-right: 16px;
}
@media only screen and (min-width: 821px) {
  .l-header .humburger-wrap .incart-btn {
    margin-right: 80px;
  }
}
.l-header .humburger-wrap .incart-btn a {
  width: 32px;
  height: 28px;
  display: block;
  background: url(../img/common/cart_black.png) no-repeat center top/cover;
  position: relative;
  transition: 0.3s;
}
.l-header .humburger-wrap .incart-btn a span {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  color: #fff;
  top: 0px;
  right: -5px;
  background: #ff4343;
  text-align: center;
  line-height: 1;
  font-size: 10px;
  font-weight: bold;
}
.l-header .humburger-wrap .incart-btn p {
  font-size: 12px;
  font-family: "Noto sans JP", sans-serif;
  transition: 0.3s;
}
.l-header .humburger-wrap .button-wrap {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: center;
}
.l-header .humburger-wrap .button-wrap::before {
  content: "menu";
  font-family: "Noto serif JP", serif;
  font-size: 10px;
  color: #717679;
  transition: 0.3s;
}
.l-header .humburger-wrap .button {
  position: relative;
  display: inline-block;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 100;
}
.l-header .humburger-wrap .hamburger {
  width: 28px;
  height: 18px;
  outline: none;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}
.l-header .humburger-wrap .hamburger-line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 40px;
  height: 2px;
  background-color: transparent;
  transition: inherit;
}
.l-header .humburger-wrap .hamburger-line::before,
.l-header .humburger-wrap .hamburger-line::after {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #717679;
  content: "";
  transition: inherit;
}
.l-header .humburger-wrap .hamburger-line::before {
  width: 28px;
  top: -3px;
}
.l-header .humburger-wrap .hamburger-line::after {
  width: 28px;
  top: 3px;
}
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line {
  width: 28px;
  background-color: transparent;
}
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line::before,
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line::after {
  width: 28px;
  top: 0;
  right: initial;
  left: initial;
  background-color: #717679;
}
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line::before {
  transform: rotate(35deg);
}
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line::after {
  transform: rotate(-35deg);
}
.l-header .humburger-wrap .visuallyHidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  margin: -1px;
}
.l-header .humburger-wrap .login-sp {
  margin-right: 10px;
  position: relative;
}
.l-header .humburger-wrap .login-sp::before {
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  display: block;
  width: 20px;
  height: 23px;
  background: url(../img/common/icon-login_black.png) no-repeat center top/cover;
  transition: 0.3s;
}
.l-header .humburger-wrap .login-sp input {
  background: none;
  font-family: "Noto sans JP", sans-serif;
  font-size: 12px;
  border: none;
  transition: 0.3s;
  color: #000;
  font-weight: 300;
}
.l-header .nav-wrap {
  width: 100%;
  height: 100vh;
  padding: 80px 15px 0;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 3;
  color: #fff;
}
@media only screen and (min-width: 821px) {
  .l-header .nav-wrap {
    padding: 0;
    width: 70%;
    left: initial;
  }
}
.l-header .nav-wrap .accordion {
  margin: 0 0 32px 0;
  padding: 32px 0;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
.l-header .nav-wrap .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.l-header .nav-wrap .accordion .accordion-item:last-child .accordion-title {
  margin-bottom: 0;
}
.l-header .nav-wrap .accordion .accordion-title {
  color: #717679;
  text-align: center;
  font-family: "Noto serif JP", serif;
  border-bottom: 1px solid #fff;
  font-size: 20px;
  text-align: left;
  border-top: none;
}
.l-header .nav-wrap .accordion .accordion-title.open::before {
  display: none;
}
.l-header .nav-wrap .accordion .accordion-title::after {
  border: none;
  width: 10px;
  height: 1px;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 0px;
  background: #717679;
}
.l-header .nav-wrap .accordion .accordion-title::before {
  content: "";
  display: block;
  height: 10px;
  width: 1px;
  background: #717679;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 9px;
}
.l-header .nav-wrap .accordion .accordion-content {
  justify-content: flex-start;
  padding: 0;
}
.l-header .nav-wrap .accordion .accordion-content ul {
  text-align: left;
}
.l-header .nav-wrap .accordion .accordion-content ul li {
  margin-bottom: 17px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.l-header .nav-wrap .accordion .accordion-content ul li::before {
  content: "";
  display: block;
  width: 10px;
  height: 1px;
  background: #717679;
  margin-right: 10px;
}
.l-header .nav-wrap .accordion .accordion-content ul li a {
  font-size: 16px;
  color: #717679;
}
.l-header .nav-wrap .nav_botton ul {
  display: flex;
  flex-wrap: wrap;
}
.l-header .nav-wrap .nav_botton ul li {
  width: 50%;
  margin-bottom: 18px;
}
.l-header .nav-wrap .nav_botton ul li a {
  font-size: 20px;
  font-family: "Noto serif JP", serif;
}
.l-header .nav-wrap .nav_pc {
  display: none;
}
@media only screen and (min-width: 821px) {
  .l-header .nav-wrap .nav_pc {
    display: block;
    position: relative;
  }
}
.l-header .nav-wrap .nav_pc .nav_inner {
  padding: 70px 0 40px 140px;
}
.l-header .nav-wrap .nav_pc nav {
  display: flex;
  border-bottom: 1px solid #A9ADAE;
  margin-bottom: 20px;
}
.l-header .nav-wrap .nav_pc nav .nav_content {
  margin-right: 100px;
}
.l-header .nav-wrap .nav_pc nav .nav_content .nav_list {
  margin-bottom: 30px;
}
.l-header .nav-wrap .nav_pc nav .nav_content .nav_list h3 {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-size: 30px;
  margin-bottom: 18px;
}
.l-header .nav-wrap .nav_pc nav .nav_content .nav_list ul li {
  margin-bottom: 10px;
}
.l-header .nav-wrap .nav_pc nav .nav_content .nav_list ul li a {
  letter-spacing: 0.04em;
  display: flex;
  align-items: center;
}
.l-header .nav-wrap .nav_pc nav .nav_content .nav_list ul li a::before {
  content: "";
  display: block;
  width: 10px;
  height: 1px;
  background: #717679;
  margin-right: 10px;
}
.l-header .nav-wrap .nav_pc nav .nav_content .nav_list ul li ul {
  -moz-column-count: 3;
  column-count: 3;
  margin-top: 10px;
}
.l-header .nav-wrap .nav_pc nav .nav_content .nav_list ul li ul li a {
  font-size: 14px;
}
.l-header .nav-wrap .nav_pc .nav_bottom {
  display: flex;
  margin-bottom: 20px;
}
.l-header .nav-wrap .nav_pc .nav_bottom a {
  text-decoration: underline;
  margin-right: 10px;
}
@media only screen and (min-width: 821px) {
  .l-header .nav-wrap .nav_pc .nav_bottom a {
    font-size: 12px;
  }
}
.l-header .nav-wrap .reserve_links {
  background: #B49C6C;
}
.l-header .nav-wrap .reserve_links .cta {
  background: transparent;
  display: flex;
  max-width: 720px;
  margin: 0 auto;
  justify-content: space-between;
  padding: 24px 0;
}
.l-header .nav-wrap .reserve_links .cta .btn {
  margin-bottom: 0;
  width: 48%;
}
@media only screen and (min-width: 821px) {
  .l-header .nav-wrap .reserve_links .cta .btn {
    height: 78px;
  }
  .l-header .nav-wrap .reserve_links .cta .btn span {
    font-size: 20px;
  }
}
@media only screen and (min-width: 821px) {
  .l-header .nav-wrap .reserve_links .cta .btn.tel {
    font-size: 14px;
  }
  .l-header .nav-wrap .reserve_links .cta .btn.tel span {
    font-size: 32px;
  }
}

.nav-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media only screen and (min-width: 821px) {
  .nav-category {
    display: none;
  }
}
.nav-category figure {
  width: 30%;
  margin-bottom: 26px;
  text-align: center;
}
.nav-category figure img {
  display: block;
  height: 57px;
  width: auto;
  -o-object-fit: cover;
  object-fit: cover;
  margin: 0 auto 10px;
}
.nav-category figure figcaption {
  font-size: 14px;
  font-family: "Noto serif JP", serif;
  line-height: 1.4;
}

.reserve_btn_wrapper {
  margin: 0 calc(50% - 50vw);
  background: #B49C6C;
}

.reserve_btn {
  color: #fff;
  font-family: "Noto serif JP", serif;
  text-align: center;
  width: 100%;
  background: #B49C6C;
  padding: 22px 0;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.reserve_btn span {
  color: #fff;
}
.reserve_btn::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: url(../img/common/icon_reserve.png) no-repeat center top/cover;
  margin-right: 10px;
  z-index: 10;
}
@media only screen and (min-width: 821px) {
  .reserve_btn {
    display: none;
  }
}

.icons_sp {
  display: flex;
  margin-bottom: 32px;
}
@media only screen and (min-width: 821px) {
  .icons_sp {
    display: none;
  }
}
.icons_sp a {
  width: 30px;
  height: 30px;
  display: block;
}
.icons_sp a:first-child {
  margin-right: 10px;
}

.icons_pc {
  display: flex;
  display: none;
}
@media only screen and (min-width: 821px) {
  .icons_pc {
    display: flex;
    margin-left: 20px;
  }
}
.icons_pc a {
  width: 30px;
  height: 30px;
  display: block;
}
.icons_pc a:first-child {
  margin-right: 10px;
}

footer {
  padding: 50px 16px 0;
  border-top: 1px solid #C6C8C9;
  margin-top: 100px;
  position: relative;
}
@media only screen and (min-width: 821px) {
  footer {
    margin-top: 290px;
    width: calc(100% - 60px);
  }
}
footer .to-top {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 20px;
  top: -70px;
  transform: rotate(-90deg);
}
@media only screen and (min-width: 821px) {
  footer .to-top {
    width: 90px;
    height: 90px;
    right: 120px;
    top: -150px;
  }
}
footer .cta {
  margin-bottom: 50px;
}
footer .footer_nav {
  margin-bottom: 18px;
  font-family: "Noto sans JP", sans-serif;
}
footer .footer_links {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
@media only screen and (min-width: 821px) {
  footer .footer_links {
    margin-bottom: 0;
  }
}
footer .footer_links li:first-of-type {
  margin-right: 18px;
}
footer .footer_links li a {
  font-size: 13px;
  text-decoration: underline;
  font-family: "Noto sans JP", sans-serif;
}
footer .icons {
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
}
@media only screen and (min-width: 821px) {
  footer .icons {
    order: 3;
    margin-bottom: 0;
    margin-left: 60px;
  }
}
footer .icons a {
  width: 30px;
  height: 30px;
  display: block;
}
footer .icons a:first-of-type {
  margin-right: 10px;
}
footer .logo {
  width: 247px;
  margin: 0 auto 30px;
}
@media only screen and (min-width: 821px) {
  footer .logo {
    margin: 0 60px 0 0;
  }
}
footer .copy {
  text-align: center;
  font-size: 10px;
  padding: 28px 0;
  border-top: 1px solid #C0C0C0;
  margin: 0 calc(50% - 50vw);
  letter-spacing: 0.2em;
}
footer .nav_footer_pc {
  display: flex;
  max-width: 1000px;
  justify-content: space-between;
  margin: 0 auto 120px;
  font-family: "Noto sans JP", sans-serif;
}
@media only screen and (min-width: 821px) {
  footer .nav_footer_pc ul {
    width: 18%;
  }
}
footer .nav_footer_pc ul .nav_title {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C6C8C9;
}
footer .nav_footer_pc ul li {
  margin-bottom: 10px;
}
@media only screen and (min-width: 821px) {
  footer .flex {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }
}