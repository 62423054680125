@import "../Foundation/_mediaqueries";
@import "../Foundation/fontsize";

footer {
  padding: 50px 16px 0;
  border-top: 1px solid #C6C8C9;
  margin-top: 100px;
  position: relative;

  @include pc {
    margin-top: 290px;
    width: calc(100% - 60px);
  }

  .to-top {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 20px;
    top: -70px;
    transform: rotate(-90deg);

    @include pc {
      width: 90px;
      height: 90px;
      right: 120px;
      top: -150px;
    }
  }

  .cta {
    margin-bottom: 50px;
  }

  .footer_nav {
    margin-bottom: 18px;
    font-family: $font-default;
  }

  .footer_links {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    @include pc {
      margin-bottom: 0;
    }

    li {
      &:first-of-type {
        margin-right: 18px;
      }

      a {
        font-size: 13px;
        text-decoration: underline;
        font-family: $font-default;
      }
    }
  }

  .icons {
    display: flex;
    justify-content: center;
    margin-bottom: 18px;

    @include pc {
      order: 3;
      margin-bottom: 0;
      margin-left: 60px;
    }

    a {
      width: 30px;
      height: 30px;
      display: block;

      &:first-of-type {
        margin-right: 10px;
      }
    }
  }

  .logo {
    width: 247px;
    margin: 0 auto 30px;

    @include pc {
      margin: 0 60px 0 0;
    }
  }

  .copy {
    text-align: center;
    font-size: 10px;
    padding: 28px 0;
    border-top: 1px solid #C0C0C0;
    margin: 0 calc(50% - 50vw);
    letter-spacing: 0.2em;
  }

  .nav_footer_pc {
    display: flex;
    max-width: 1000px;
    justify-content: space-between;
    margin: 0 auto 120px;
    font-family: $font-default;

    ul {
      @include pc {
        width: 18%;
      }

      .nav_title {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #C6C8C9;
      }

      li {
        margin-bottom: 10px;
      }
    }
  }

  .flex {
    @include pc {
      display: flex;
      justify-content: center;
      margin-bottom: 80px;
    }
  }
}