// フォントサイズ
// @include font-size(14)
// こんな感じで記述する。
// vwなのでスマホのとき、でかすぎる時があるので、
// 大きい文字(fs32とか)は、SPのみ、
// font-size: 6vw; こんな感じで上書きすることを許す。
// 案件によってブレイクポイントは変更してね。


@function get_vw($size, $viewport:375) {
  $rate: calc(100 / $viewport);
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:10) {
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}