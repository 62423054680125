@import "../Foundation/_mediaqueries";

body.is-drawerActive {
  overflow-y: hidden;

  .humburger-wrap .button-wrap::before {
    content: 'close';
  }
}

body.is-drawerActive .l-header .nav-wrap {
  opacity: 1;
  visibility: visible;
}

.l-header {
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;

  @include pc {
    background: none;
  }

  .inner {
    padding: 18px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include pc() {
      padding: 10px 10px 20px 36px;
    }
  }

  .logo-wrap {
    position: relative;
    z-index: 9999;

    img {
      width: auto;
      height: 32px;

      @include pc {
        height: 45px;
      }
    }
  }

  .humburger-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @include pc() {
      align-items: center;
      position: relative;
      z-index: 9999;
    }

    .incart-btn {
      text-align: center;
      margin-right: 16px;

      @include pc() {
        margin-right: 80px;
      }

      a {
        width: 32px;
        height: 28px;
        display: block;
        background: url(../img/common/cart_black.png) no-repeat center top/cover;
        position: relative;
        transition: 0.3s;

        span {
          position: absolute;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          display: block;
          color: #fff;
          top: 0px;
          right: -5px;
          background: #ff4343;
          text-align: center;
          line-height: 1;
          font-size: 10px;
          font-weight: bold;
        }
      }

      p {
        font-size: 12px;
        font-family: $font-default;
        transition: 0.3s;
      }
    }

    .button-wrap {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      justify-content: center;

      &::before {
        content: "menu";
        font-family: $font-serif;
        font-size: 10px;
        color: $color-default;
        transition: 0.3s;
      }
    }

    .button {
      position: relative;
      display: inline-block;
      text-decoration: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      z-index: 100;
    }

    .hamburger {
      width: 28px;
      height: 18px;
      outline: none;
      transition: all 0.3s ease-in-out;
      margin-bottom: 0;
    }

    .hamburger-line {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 40px;
      height: 2px;
      background-color: transparent;
      transition: inherit;
    }

    .hamburger-line::before,
    .hamburger-line::after {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $color-default;
      content: "";
      transition: inherit;
    }

    .hamburger-line::before {
      width: 28px;
      top: -3px;
    }

    .hamburger-line::after {
      width: 28px;
      top: 3px;
    }

    .hamburger[aria-expanded="true"] .hamburger-line {
      width: 28px;
      background-color: transparent;
    }

    .hamburger[aria-expanded="true"] .hamburger-line::before,
    .hamburger[aria-expanded="true"] .hamburger-line::after {
      width: 28px;
      top: 0;
      right: initial;
      left: initial;
      background-color: $color-default;
    }

    .hamburger[aria-expanded="true"] .hamburger-line::before {
      transform: rotate(35deg);
    }

    .hamburger[aria-expanded="true"] .hamburger-line::after {
      transform: rotate(-35deg);
    }

    .visuallyHidden {
      position: absolute;
      white-space: nowrap;
      width: 1px;
      height: 1px;
      overflow: hidden;
      border: 0;
      padding: 0;
      clip: rect(0 0 0 0);
      -webkit-clip-path: inset(50%);
      clip-path: inset(50%);
      margin: -1px;
    }

    .login-sp {
      margin-right: 10px;
      position: relative;

      &::before {
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: block;
        width: 20px;
        height: 23px;
        background: url(../img/common/icon-login_black.png) no-repeat center top/cover;
        transition: 0.3s;
      }

      input {
        background: none;
        font-family: $font-default;
        font-size: 12px;
        border: none;
        transition: 0.3s;
        color: #000;
        font-weight: 300;
      }
    }
  }

  .nav-wrap {
    width: 100%;
    height: 100vh;
    padding: 80px 15px 0;
    background-color: $color-white;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 3;
    color: #fff;

    @include pc() {
      padding: 0;
      width: 70%;
      left: initial;
    }

    .accordion {
      margin: 0 0 32px 0;
      padding: 32px 0;
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #ccc;

      .accordion-item {
        &:last-child {
          margin-bottom: 0;

          .accordion-title {
            margin-bottom: 0;
          }
        }
      }

      .accordion-title {
        color: $color-default;
        text-align: center;
        font-family: $font-serif;
        border-bottom: 1px solid #fff;
        font-size: 20px;
        text-align: left;
        border-top: none;

        &.open {
          &::before {
            display: none;
          }
        }

        &::after {
          border: none;
          width: 10px;
          height: 1px;
          transform: translate(-50%, -50%);
          top: 50%;
          right: 0px;
          background: $color-default;
        }

        &::before {
          content: '';
          display: block;
          height: 10px;
          width: 1px;
          background: $color-default;
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          right: 9px;
        }
      }

      .accordion-content {
        justify-content: flex-start;
        padding: 0;

        ul {
          text-align: left;

          li {
            margin-bottom: 17px;
            padding-left: 10px;
            display: flex;
            align-items: center;

            &::before {
              content: '';
              display: block;
              width: 10px;
              height: 1px;
              background: $color-default;
              margin-right: 10px;
            }

            a {
              font-size: 16px;
              color: $color-default;
            }
          }
        }
      }
    }

    .nav_botton {
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 50%;
          margin-bottom: 18px;

          a {
            font-size: 20px;
            font-family: $font-serif;
          }
        }
      }
    }

    .nav_pc {
      display: none;

      @include pc {
        display: block;
        position: relative;
      }

      .nav_inner {
        padding: 70px 0 40px 140px;
      }

      nav {
        display: flex;
        border-bottom: 1px solid #A9ADAE;
        margin-bottom: 20px;

        .nav_content {
          margin-right: 100px;

          .nav_list {
            margin-bottom: 30px;

            h3 {
              font-family: $font-play;
              font-style: italic;
              font-size: 30px;
              margin-bottom: 18px;
            }

            ul {
              li {
                margin-bottom: 10px;

                a {
                  letter-spacing: 0.04em;
                  display: flex;
                  align-items: center;

                  &::before {
                    content: '';
                    display: block;
                    width: 10px;
                    height: 1px;
                    background: $color-default;
                    margin-right: 10px;
                  }
                }

                ul {
                  -moz-column-count: 3;
                  column-count: 3;
                  margin-top: 10px;

                  li {
                    a {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .nav_bottom {
        display: flex;
        margin-bottom: 20px;

        a {
          text-decoration: underline;
          margin-right: 10px;

          @include pc {
            font-size: 12px;
          }
        }
      }
    }

    .reserve_links {
      background: #B49C6C;

      .cta {
        background: transparent;
        display: flex;
        max-width: 720px;
        margin: 0 auto;
        justify-content: space-between;
        padding: 24px 0;

        .btn {
          margin-bottom: 0;
          width: 48%;

          @include pc {
            height: 78px;

            span {
              font-size: 20px;
            }
          }

          &.tel {
            @include pc {
              font-size: 14px;

              span {
                font-size: 32px;
              }
            }
          }
        }
      }
    }
  }


}

.nav-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include pc {
    display: none;
  }

  figure {
    width: 30%;
    margin-bottom: 26px;
    text-align: center;

    img {
      display: block;
      height: 57px;
      width: auto;
      -o-object-fit: cover;
      object-fit: cover;
      margin: 0 auto 10px;
    }

    figcaption {
      font-size: 14px;
      font-family: $font-serif;
      line-height: 1.4;
    }
  }
}

.reserve_btn_wrapper {
  margin: 0 calc(50% - 50vw);
  background: #B49C6C;
}

.reserve_btn {
  color: #fff;
  font-family: $font-serif;
  text-align: center;
  width: 100%;
  background: #B49C6C;
  padding: 22px 0;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  span {
    color: #fff;
  }

  &::before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background: url(../img/common/icon_reserve.png) no-repeat center top/cover;
    margin-right: 10px;
    z-index: 10;
  }

  @include pc {
    display: none;
  }
}

.icons_sp {
  display: flex;
  margin-bottom: 32px;

  @include pc {
    display: none;
  }

  a {
    width: 30px;
    height: 30px;
    display: block;

    &:first-child {
      margin-right: 10px;
    }
  }
}

.icons_pc {
  display: flex;
  display: none;

  @include pc {
    display: flex;
    margin-left: 20px;

  }

  a {
    width: 30px;
    height: 30px;
    display: block;

    &:first-child {
      margin-right: 10px;
    }
  }
}